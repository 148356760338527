.dark .ui.table:not(.unstackable) tr > td > p{
    color: white !important;
}

@media (max-width: 767px) {
    .ui.table:not(.unstackable){
        width: 100%;
        display: block !important;
        overflow-x: auto !important;
        border: none !important;
        padding: 0px 27px 0px 27px;
    }

    .ui.table:not(.unstackable) > tbody{
        width: 100%;
    }

    .ui.table:not(.unstackable) tr{
       display: table-row !important;
       border-bottom: 1px solid #C1C1C1 !important;
       border-collapse: collapse;
    }

    .ui.table:not(.unstackable) tr > th{
        display: table-cell !important;
        color: #8D9199 !important;
        padding: 8px 24px 8px 24px !important;
        min-width: 200px;
    }

    .ui.table:not(.unstackable) tr > th > p{
        font-size: 12px !important;
        line-height: 13.55px !important;
        font-weight: 700 !important;
    }

    .ui.table:not(.unstackable) tr > td > p{
        font-size: 11.2px !important;
        line-height: 13.55px !important;
    }

    .ui.table:not(.unstackable) tr > td{
        min-width: 200px;
        display: table-cell !important;
        padding: 8px 24px 8px 24px !important;
    }

    .ui.table:not(.unstackable) th:first-child{
        font-weight: 700 !important;
    }

    .ui.table:not(.unstackable) td:first-child{
        font-weight: 400 !important;
    }
}
