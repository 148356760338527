select {
  padding: 10px;
  padding-right: 30px;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('./dropdown.svg');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: 1.3em auto, 100%;
}

select::-ms-expand {
  display: none;
}