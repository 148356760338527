.searchBarAnimation {
  animation: widthExtension 0.3s ease 0s 1 normal;
  animation-fill-mode: forwards;
}

@keyframes widthExtension {
  0% {
    opacity: 0;
    background-color: transparent;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
    background-color: white;
    border-style: none;
  }
}

#search-input {
  color: #7a7a7a;
  font-size: 16px;
  height: 100%;
  margin-top: 2px;
  font-weight: 400;

  .dark & {
    color: #F6F6F6;
    &::placeholder {
      color: #F6F6F6;
    }
  }

  &::placeholder {
    color: #7a7a7a;
  }

  &::selection {
    background: #fcbc00;
  }

  .searchOpen & {
    color: #7a7a7a;
    &::placeholder {
      color: #7a7a7a;
    }
  }
}
