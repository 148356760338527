.bottomYellowBorder:after {
  display: block;
  content: '';
  border-top: solid 4px var(--ds-yellow-400);
  transform: scaleX(0);
  margin-top: 6px;
  transition: transform 250ms ease-in-out;
}

.bottomYellowBorder:after {
  transform-origin: 100% 50%;
}
.bottomYellowBorder:hover:after {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}

.bottomWhiteBorder:after {
  display: block;
  content: '';
  border-top: solid 4px white;
  transform: scaleX(0);
  margin-top: 6px;
  transition: transform 250ms ease-in-out;
}

.bottomWhiteBorder:after {
  transform-origin: 100% 50%;
}
.bottomWhiteBorder:hover:after {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}

/* Código para ajustar o tamanho do container que envolve os sub-menus */
ul.lista a::after {
  display: block;
  content: attr(title);
  font-weight: 800;
  height: 1px;
  color: transparent;
  overflow: hidden;
  visibility: hidden;
}
