 /*
  override swiper style , USE !important
  swiper width must be defined using tailwind:
  in the div below the swiper
  [&_div.swiper-button-next]:!w-yourWidth
  [&_div.swiper-button-prev]:!w-yourWidth

code for smooth linear gradient on https://github.com/FaboBorgesLima/web-dev-utils
*/
div.swiper-button-prev,
div.swiper-button-next {
  position: absolute !important;
  top: 0 !important;
  height: 100% !important;
  margin-top: 0px !important;
  border-radius: 0% !important;
  z-index: 2;
  background: linear-gradient(
    180deg,
    #d9d9d9 0%,
    rgba(217, 217, 217, 0.48) 48.62%,
    rgba(217, 217, 217, 0) 82.53%
  );
}

.swiper-wrapper {
  width: 100% !important;
}

.swiper-slide-newsCard {
  height: 550px !important;
  width: 380px !important;
  margin-bottom: 1px !important;
}

@media (max-width: 768px) {
  .swiper-slide-newsCard {
    width: 100% !important;
    height: 500px !important;
  }
}

.swiper-slide-newsCard.no-image {
  height: 278px !important;
  width: 380px !important;
  margin-bottom: 1px !important;
}

@media (max-width: 768px) {
  .swiper-slide-newsCard.no-image {
    width: 100% !important;
    height: 197px !important;
  }
}

.swiper-slide-ourPeople {
  height: auto !important;
  width: 295px !important;

  @media (max-width: 768px) {
    width: 100% !important;
    height: auto !important;
  }
}

.banner .swiper-slide {
  width: 100% !important;
}

.swiper-container {
  position: static !important;
  width: 100% !important;
}

.swiper-button-prev {
  &::after {
    content: '' !important;
    width: 100% !important;
    height: 100% !important;
    background: url('data:image/svg+xml;utf8,<svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2L2 8L8 14" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
      no-repeat center center;
  }

  .dark &::after {
    content: '' !important;
    width: 100% !important;
    height: 100% !important;
    background: url('data:image/svg+xml;utf8,<svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2L2 8L8 14" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
      no-repeat center center;
  }

  &.swiper-button-disabled {
    display: none;
  }

  & {
    left: 0 !important;
    background-image: linear-gradient(
      90deg,
      #fafdfd 0%,
      rgba(0, 0, 0, 0) 100%
    ) !important;
  }
  .bg-none-swiper & {
    background-image: none !important;
  }

  .bg-none-swiper .dark & {
    background-image: none !important;
  }
  /*code for smooth linear gradient on https://github.com/FaboBorgesLima/web-dev-utils */
  .smoother-swiper & {
    right: 0 !important;
    background-image: linear-gradient(
      270deg,
      rgba(250, 253, 253, 0) 5%,
      rgba(250, 253, 253, 0) 15%,
      rgba(250, 253, 253, 0) 25%,
      rgba(250, 253, 253, 0) 35%,
      rgba(250, 253, 253, 0.05) 45%,
      rgba(250, 253, 253, 0.1) 55%,
      rgba(250, 253, 253, 0.18) 65%,
      rgba(250, 253, 253, 0.26) 75%,
      rgba(250, 253, 253, 0.34) 85%,
      rgba(250, 253, 253, 0.5) 100%
    ) !important;
  }

  .dark & {
    left: 0 !important;
    background-image: linear-gradient(
      90deg,
      #001326 0%,
      rgba(0, 0, 0, 0) 100%
    ) !important;
  }

  .dark .smoother-swiper & {
    right: 0 !important;
    background-image: linear-gradient(
      270deg,
      rgba(0, 19, 38, 0) 0%,
      rgba(0, 19, 38, 0.03) 12.5%,
      rgba(0, 19, 38, 0.125) 25%,
      rgba(0, 19, 38, 0.281) 37.5%,
      rgba(0, 19, 38, 0.5) 50%,
      rgba(0, 19, 38, 0.71) 62.5%,
      rgba(0, 19, 38, 0.875) 75%,
      rgba(0, 19, 38, 0.968) 87.5%,
      rgb(0, 19, 38) 100%
    ) !important;
  }
}

.swiper-button-next {
  &::after {
    content: '' !important;
    width: 100% !important;
    height: 100% !important;
    background: url('data:image/svg+xml;utf8,<svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2L8 8L2 14" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
      no-repeat center center;
  }

  .dark &::after {
    content: '' !important;
    width: 100% !important;
    height: 100% !important;
    background: url('data:image/svg+xml;utf8,<svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2L8 8L2 14" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
      no-repeat center center;
  }

  &.swiper-button-disabled {
    display: none;
  }

  & {
    right: 0 !important;
    background-image: linear-gradient(
      270deg,
      #fafdfd 0%,
      rgba(0, 0, 0, 0) 100%
    ) !important;
  }

  .dark & {
    right: 0 !important;
    background-image: linear-gradient(
      270deg,
      rgb(0, 19, 38) 0%,
      rgba(0, 0, 0, 0) 100%
    ) !important;
  }

  .bg-none-swiper & {
    background-image: none !important;
  }

  .bg-none-swiper .dark & {
    background-image: none !important;
  }

  .dark .smoother-swiper & {
    right: 0 !important;
    background-image: linear-gradient(
      90deg,
      rgba(0, 19, 38, 0) 0%,
      rgba(0, 19, 38, 0.03) 12.5%,
      rgba(0, 19, 38, 0.125) 25%,
      rgba(0, 19, 38, 0.281) 37.5%,
      rgba(0, 19, 38, 0.5) 50%,
      rgba(0, 19, 38, 0.71) 62.5%,
      rgba(0, 19, 38, 0.875) 75%,
      rgba(0, 19, 38, 0.968) 87.5%,
      rgb(0, 19, 38) 100%
    ) !important;
  }
  .smoother-swiper & {
    right: 0 !important;
    background-image: linear-gradient(
      90deg,
      rgba(250, 253, 253, 0) 5%,
      rgba(250, 253, 253, 0) 15%,
      rgba(250, 253, 253, 0) 25%,
      rgba(250, 253, 253, 0) 35%,
      rgba(250, 253, 253, 0.05) 45%,
      rgba(250, 253, 253, 0.1) 55%,
      rgba(250, 253, 253, 0.18) 65%,
      rgba(250, 253, 253, 0.26) 75%,
      rgba(250, 253, 253, 0.34) 85%,
      rgba(250, 253, 253, 0.5) 100%
    ) !important;
  }
}
